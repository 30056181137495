import { useCallback } from "react";
import _ from "lodash";
import { WebleadPreviewResponse, webleadPreview } from "../common/api";

import { graphql, useStaticQuery } from "gatsby";

export type customerType = "commercial" | "residential" | "industrial";
export const customerTypes = ["commercial", "residential", "industrial"];

type CmsCity = {
  name: string;
  slug: string;
  dac_region?: string;
  pdbt_region?: string;
  t1_zone?: string;
  solar_yield_postal_code?: string;
};

type CmsCityLookupFn = (slug: string) => CmsCity | null;

function useCmsCityLookup(): CmsCityLookupFn {
  const { allStrapiCity } = useStaticQuery(graphql`
    query {
      allStrapiCity(sort: { fields: [name], order: ASC }) {
        nodes {
          name
          slug
          dac_region
          pdbt_region
          t1_zone
          solar_yield_postal_code
        }
      }
    }
  `);

  return useCallback(
    (slug: string) => {
      const cityNode = allStrapiCity.nodes.find((n) => n.slug === slug);
      return cityNode;
    },
    [allStrapiCity],
  );
}

function getPostalCode(cityNode: CmsCity | null): string | null {
  if (!cityNode) {
    return null;
  }

  const postalCode = cityNode.solar_yield_postal_code;
  return postalCode ?? null;
}

function getTarifa(
  cityNode: CmsCity | null,
  customerType: customerType,
): string | null {
  if (!cityNode) {
    return null;
  }

  if (customerType === "commercial") {
    const pdbtRegion = cityNode?.pdbt_region;
    if (!pdbtRegion) {
      return null;
    }

    return `pdbt-${pdbtRegion}`;
  }

  if (customerType === "residential") {
    const dacRegion = cityNode?.dac_region;
    const t1Zone = cityNode?.t1_zone;
    if (!dacRegion || !t1Zone) {
      return null;
    }

    return `${t1Zone}-${dacRegion}`;
  }

  return null;
}

export type PricerFormValues = {
  city: string;
  state: string;
  lastBillMxn: string;
  tarifa: string;
  postalCode: string;
};

function featureFlagToAssumptionSet(flag: string): string {
  if (flag === "interactive_low_price") {
    return "online_t1";
  }

  return "default";
}

type UiProposalPreviewParams = {
  lastBillMxn: string;
  panels?: number;
  city: string;
  customerType: customerType;
  variant: string;
  riskCategory: string;
};

type ApiWebleadPreviewPayload = {
  lastBillMxn: string;
  tarifa: string;
  postalCode: string;
  assumptionSet: string;
  panels?: number;
};

type UseUiFriendlyWebleadPreviewResult = (
  params: UiProposalPreviewParams,
) => ApiWebleadPreviewPayload;

/**
 * A wrapper around the /proposals/weblead endpoint but that automatically looks
 * up the postal code before submitting
 */
export function useUiFriendlyWebleadPreviewParams(): UseUiFriendlyWebleadPreviewResult {
  const lookupCmsCity = useCmsCityLookup();

  const callApi = useCallback(
    (uiParams: UiProposalPreviewParams) => {
      const { lastBillMxn, panels, city, customerType, variant, riskCategory } =
        uiParams;
      const cmsCity = lookupCmsCity(city);
      const tarifa = getTarifa(cmsCity, customerType);
      const postalCode = getPostalCode(cmsCity);
      const assumptionSet = featureFlagToAssumptionSet(variant);
      return {
        lastBillMxn,
        tarifa,
        postalCode,
        assumptionSet,
        panels,
        riskCategory,
      };
    },
    [lookupCmsCity],
  );

  return callApi;
}
